import React, { useEffect } from 'react';
import SubmitSpinner from '../components/SubmitSpinner';
import { graphql } from 'relay-runtime';
import { useMutation } from 'react-relay';

export const LogoutMutation = graphql`
  mutation LogoutMutation($input: LoginLogoutInput!) {
    loginLogout(input: $input) {
      success
    }
  }
`;

const Logout = () => {
  const [commitMutation] = useMutation(LogoutMutation);

  useEffect(() => {
    commitMutation({
      variables: {
        input: {},
      },
      onCompleted: () => {
        window.location.href = window.RAILS_CONSTANTS['login_path'];
      },
      onError: () => {
        window.location.href = window.RAILS_CONSTANTS['login_path'];
      },
    });
  }, [commitMutation]);

  return <SubmitSpinner spinnerText="Logging out..." />;
};

export default Logout;
